$( document ).ready(function() {
	var $componentContainer = $(".component-container");
	var $commentsContainer = $(".comments-container");
	var $commentUpvoteButton = $(".comment-upvote-button");
	var $commentDownvoteButton = $(".comment-downvote-button");
	var $componentUpvoteButton = $(".component-upvote-button");
	var $componentDownvoteButton = $(".component-downvote-button");
	var $commentVoteScore = $(".comment-vote-score");
	var $contentToggle = $(".content-toggle");
	var $calendarToggle = $(".calendar-toggle");
	var $positionSelect = $(".position-select");
	var $commentInputCancel = $(".comment-input-cancel");
	var $studentContainer = $(".student-container");
	var $studentPostContent = $(".student-post-content");
	var $discussionTopic = $(".discussion-topic");
	var $studentPostButton = $(".student-post-button");
	var $generatedResponse = $(".generated-response");
	var $sortButton = $(".sort-button");
	var $addDiscussionButtom = $('.add-discussion-prompt');
	var $generateNewResponse = $('.generate-new-response');
	var $suggestRevisionButton = $('.suggest-revision-button');
	var $closeRevisionForm = $('.close-revision');
	var $closeGeneratedResponse = $('.close-generated-response');
	var $revisionForm = $('.revision-form');
	var $generatedResponseModal = $('.generated-response-modal');
	var $generatedResponseContent = $('.generated-response-content');
	var $generatedResponseConfirmation = $('.generated-response-confirmation');
	var $revisionOfField = $('#component_revision_of_id');
	var $revisionReviewContainer = $('.revision-review-container');
	var $approveRevisionButton = $('.approve-revision-button');
	var $denyRevisionButton = $('.deny-revision-button');
	var $reapproveRevisionButton = $('.reapprove-revision-button');
	var $generateTaskButton = $('.generate-task-button');
	var $generateSubTaskButton = $('.generate-sub-task-button');
	var $generateCourseTaskButton = $('.generate-course-task-button');
	var $bulkSelect = $('.bulk-select');
	var $showBulkSelect = $('.show-bulk-select');
	var $bulkSelectSave = $('.bulk-select-save');
	var $progressSpinner = $('.progress-spinner');
	var $progressSpinnerText = $('.progress-spinner-text');
	var $toggleMobileNav = $('.toggle-mobile-nav');
	var $closeMobileNav = $('.close-mobile-nav');
	var $mobileNav = $('.mobile-nav');
	var $informationIcon = $('.information-icon');
	var $informationModal = $('.information-modal');
	var $informationContent = $('.information-content');
	var $informationConfirmation = $('.information-confirmation');
	var $aiSlider = $('.ai-slider');

	var $courseSectionEditButton = $('.course-section-edit');
	var $courseSectionEditForm = $('.course-section-edit-form');

	var $showCommentsButton = $('.show-comments-button');
	var $commentsContainer = $('.comments-container');


	var $courseSectionSort = $('.course-section-sort');
	var $metricSectionSort = $('.metric-section-sort');
	var $sortBy = $('.sort-by')
	var $confirmPhoneButton = $('.confirm-phone-button');
	var $verifyPhoneButton = $('.verify-phone-button');
	var $enterPinField = $('.phone-pin-field');

	var $feedbackContainer = $('.feedback-container');
	var $feedbackContainerShown = $('.feedback-container-shown');
	var $feedbackShow = $('.feedback-show');
	var $feedbackInput = $('.feedback-input');
	var $feedbackSubmit = $('.feedback-submit');
	var $feedbackCompleted = $('.feedback-completed');

	$feedbackShow.on('click', function () {
		console.log('show feedback form')
		$(this).addClass('hidden');
		$feedbackSubmit.removeClass('hidden');
		$(this).parent('div').removeClass('feedback-container');
		$(this).parent('div').addClass('feedback-container-shown');
		$feedbackInput.removeClass('hidden');
	})


	$feedbackSubmit.on('click', function () {
		console.log('submit feedback')
		console.log($feedbackInput.data("view"));
		console.log($feedbackInput.val());
		postFeedback($feedbackInput.data("view"), $feedbackInput.val());
	})


	function feedbackSubmittedReset() {
		$feedbackInput.val("");
		$feedbackSubmit.parent('div').removeClass('feedback-container-shown');
		$feedbackSubmit.parent('div').addClass('feedback-container');
		$feedbackSubmit.addClass('hidden');
		$feedbackCompleted.removeClass('hidden');
		$feedbackCompleted.show();
		$feedbackInput.addClass('hidden');
		$feedbackCompleted.fadeOut(2000, function() {
				$feedbackCompleted.addClass('hidden');
        $feedbackShow.removeClass('hidden');
    });
	}

	$confirmPhoneButton.on('click', function () {
		$(this).addClass('hidden');
		$verifyPhoneButton.removeClass('hidden');
		$enterPinField.removeClass('hidden');
		generatePhonePin();
	})


	$courseSectionSort.on('change', function () {
		var url = "/course_sections/" + $(this).val();
		console.log(url)
		window.location.href = url
	})

	$metricSectionSort.on('change', function () {
		var url = "/metrics?id=" + $(this).val();
		console.log(url)
		window.location.href = url
	})

	$sortBy.on('change', function () {
		var url = "/course_sections/" + $(this).data('id') + "?sort_by=" + $(this).val();
		console.log(url);
		window.location.href = url
	})


	// <div class="block md:inline-block">
		// 	<% current_user.course_sections.each do |course_section| %>
		// 	<a href="/course_sections/<%= course_section.id%>" class="px-2 py-1 border border-gray-300 rounded text-xs ml-2 md:ml-2 mr-0 md:mr-2">
		// 		<%= course_section.name%>
		// 		<% if course_section.id == @course_section.id %>
		// 			<svg class="w-3 h-3 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path></svg>
		// 	<% end %>
		// 	</a>
		// <% end %>
		// </div>

	// check for change on sort, then go to sort_by
	// <!-- <a href="/course_sections/<%= @course_section.id %>?sort_by=a-to-z" 
	// class="sort-button" data-id='<%= @course_section.id %>' 
	// data-sort='a-to-z'>A->Z</a> -->


	$showCommentsButton.on('click', function () {
		$(this).text( $(this).text() == 'show comments' ? "hide comments" : "show comments");
		$(this).next('.comments-container').slideToggle('fast', function() {
		})
	})

	$toggleMobileNav.on('click', function () {
		$mobileNav.removeClass('hidden');
	});

	$closeMobileNav.on('click', function () {
		$mobileNav.addClass('hidden');
	});

	$informationIcon.on('click', function () {
		$informationModal.removeClass('hidden');
		$informationContent.text($(this).data('info'));
	})

	$informationConfirmation.on('click', function () {
		$informationModal.addClass('hidden');
		$informationContent.text("");
	})

	$informationIcon.on('mouseover', function () {
		console.log('highlighting');
		$(this).css({stroke: 'orange'});
	})

	$informationIcon.on('mouseout', function () {
		console.log('highlighting');
		$(this).css({stroke: 'gray'});
	})

	$sortButton.on('click', function () {
		var id = $(this).data('id');
		var sortMethod = $(this).data('sort');
		getSortedStudents(id, sortMethod);
	})

	$courseSectionEditButton.on('click', function () {
		$courseSectionEditForm.removeClass('hidden');
	})

	$generateNewResponse.on('click', function () {
		console.log('get a new discussion ai response');
	})

	$generateTaskButton.on('click', function () {
		console.log('get a new task ai response');
		var taskName = $(this).data('task-name');
		var topicId = $(this).data('topic-id');
		console.log(taskName);
		console.log(topicId);
		// TODO: change title based on taskName in the future
		showProgressSpinner("now communicating with CourseCollab AI");
		generateTasks(taskName, topicId);
	})


	$generateSubTaskButton.on('click', function () {
		console.log('get a new sub task ai response');
		var taskName = $(this).data('task-name');
		var subTopicId = $(this).data('sub-topic-id');
		showProgressSpinner("now communicating with CourseCollab AI");
		generateSubTasks(taskName, subTopicId);
	})

	$generateCourseTaskButton.on('click', function () {
		console.log('get a new task ai response');
		var taskName = $(this).data('task-name');
		var courseId = $(this).data('master-course-id');
		console.log(taskName);
		console.log(courseId);
		showProgressSpinner("now communicating with CourseCollab AI");
		generateCourseTasks(taskName, courseId);
	})


	$addDiscussionButtom.click(function() {
		console.log('clicked scroll');
    document.getElementById("create-discussion-topic").scrollIntoView({ behavior: 'smooth'});
	});

	$positionSelect.on('change', function () {
		console.log($(this).val());
		$(this).siblings('.save-position-button').show();
	})

	$contentToggle.on('click',  function () {
		console.log('toggle clicked');
		$(this).closest('.component-description').find('.component-content').toggle();
	})

	$calendarToggle.on('click',  function () {
		console.log('calendar toggle clicked');
		if ($(this).parent('div').hasClass('student-container')) {
			$(this).siblings('.student-calendar').addClass('block');
			$(this).siblings('.student-calendar').removeClass('hidden');
			$(this).parent('.student-container').removeClass('student-container').addClass('student-container-selected');
		} else if ($(this).parent('div').hasClass('student-container-selected')) {
			$(this).siblings('.student-calendar').removeClass('block');
			$(this).siblings('.student-calendar').addClass('hidden');
			$(this).parent('div').removeClass('student-container-selected').addClass('student-container');
		}
		
	})

	
	$studentContainer.on('click', '.student-date-select', function () {
		console.log("creating a contact for this student");
		console.log($(this).data('student'));
		console.log("on this date");
		console.log($(this).data('date'));
		saveStudentContact($(this).data('student'), $(this).data('date'), $(this))
		
	})

	$studentPostButton.on('click', function () {
		topic = $discussionTopic.val();
		message = $studentPostContent.val();
		var slidersObject = {}
		$aiSlider.each(function () {
			console.log($(this).data('property'));
			console.log($(this).val());
			slidersObject[`${$(this).data('property')}`] = $(this).val()
		})
		console.log(slidersObject);
		showProgressSpinner("now communicating with CourseCollab AI");
		sendStudentPostToAI(topic, message, slidersObject);
	})

	$generateNewResponse.on('click', function () {
		topic = $discussionTopic.val();
		message = $studentPostContent.val();
		var slidersObject = {}
		$aiSlider.each(function () {
			console.log($(this).data('property'));
			console.log($(this).val());
			slidersObject[`${$(this).data('property')}`] = $(this).val()
		})
		console.log(slidersObject);
		showProgressSpinner("now communicating with CourseCollab AI");
		sendStudentPostToAI(topic, message, slidersObject);
	})


	
	



	$commentInputCancel.on('click', function () {
		resetCommentButton($(this).parent().find('.submit-comment-button'));
	})

	$componentContainer.on('click', '.add-comment-button', function () {
		console.log('button clicked');
		$(this).siblings('.comment-input').removeClass('hidden');
		$(this).siblings('.comment-file-upload').removeClass('hidden');
		$(this).siblings('.comment-input-cancel').removeClass('hidden');
		$(this).removeClass('bg-green-600');
		$(this).addClass('bg-blue-600');
		$(this).text("submit");
		$(this).removeClass("add-comment-button");
		$(this).addClass("submit-comment-button");
	})

	$componentContainer.on('click', '.save-position-button', function () {
		console.log('setting new position...');
		setNewPosition($(this).data('component-id'), $(this).siblings('.position-select').val());
	})

	$componentContainer.on('click', '.submit-comment-button', function () {
		console.log('saving comment...');
		var commentInput = $(this).siblings('.comment-input');
		console.log(commentInput.data('component-id'));
		console.log(commentInput.val());
		var fileInput = $(this).parent().find(".comment-file-upload")[0];
		var file = fileInput.files[0];
		console.log(file);
		saveComment(commentInput.val(), commentInput.data('component-id'), file, $(this));
		resetCommentButton($(this))
	})


	$componentContainer.on('click', '.component-upvote-button', function () {
		console.log('component upvote button clicked');
		componentUpvote($(this).data('component-id'), $(this));
	})

	$componentContainer.on('click', '.component-downvote-button', function () {
		console.log('component downvote button clicked');
		componentDownvote($(this).data('component-id'), $(this));
	})

	$showBulkSelect.on('click', function () {
		console.log('showing all select inputs');
		if ($bulkSelect.hasClass('hidden')) {
			$bulkSelect.removeClass('hidden');
		} else {
			$bulkSelect.addClass('hidden');
		}
	})

	$bulkSelectSave.on('click', function () {
		console.log('saving all selected inputs');
		var selectedStudents = [];
		$bulkSelect.each(function() {
			if ($(this).prop('checked')) {
				selectedStudents.push($(this).val());
			}
		})
		console.log(selectedStudents);
		saveBulkSelected(selectedStudents);
	})


	$approveRevisionButton.on('click', function () {
		console.log('approving this revision');
		updateRevision($(this).data('revision-id'), true)
	})

	$denyRevisionButton.on('click', function () {
		console.log('denying this revision');
		updateRevision($(this).data('revision-id'), false)
	})

	$reapproveRevisionButton.on('click', function () {
		console.log('re-approving this revision');
		if (confirm('Are you sure you want to re-approve this component and archive the existing component?')) {
  			// Save it!
  	console.log('Thing was saved to the database.');
		} else {
  // Do nothing!
  		console.log('Thing was not saved to the database.');
		}
		// updateRevision($(this).data('revision-id'), true)
	})


	$commentsContainer.on('click', '.comment-upvote-button', function () {
		console.log('comment upvote button clicked');
		commentUpvote($(this).data('comment-id'), $(this));
	})

	$commentsContainer.on('click', '.comment-downvote-button', function () {
		console.log('comment downvote button clicked');
		commentDownvote($(this).data('comment-id'), $(this));
	})

	$closeRevisionForm.on('click', function () {
		$revisionForm.addClass('hidden');
	})

	$closeGeneratedResponse.on('click', function () {
		$generatedResponseModal.addClass('hidden');
	})

	$suggestRevisionButton.on('click', function () {
		// TODO
		// pass the right component id to the revision form
		// add cancel button for modal
		console.log($(this).data('component-id'));
		$('.modal-title').text("Suggest Revision of " + $(this).data('component-name'));
		$revisionForm.removeClass('hidden');
		$revisionOfField.val($(this).data('component-id'));
	})

	$generatedResponse.on('click', function () {
		copyText();
		$('.copy-notification').removeClass('hidden');
	})

	$('.copy-generated-response').on('click', function () {
		copyText();
	})

	$generatedResponseConfirmation.on('click', function () {
		copyResponseText();
		$generatedResponseModal.addClass('hidden');
	})

	function showProgressSpinner(title) {
		$progressSpinner.removeClass('hidden');
	}

	function hideProgressSpinner() {
		$progressSpinner.addClass('hidden');
	}
	function renderAIResponse(response) {
		hideProgressSpinner();
		// old rendering start
		// $generatedResponse.removeClass('hidden');
		// $generatedResponse.text(response);
		// $('.generate-new-response').removeClass('hidden');
		// old rendering end

		 // new rendering start
		$('.generated-response-modal').removeClass('hidden');
		$('.generated-response-modal-title').text("AI Discussion Response");
		var obj = $('.generated-response-content').text(response);
		obj.html(obj.html().replace(/^\n/, ''));
		obj.html(obj.html().replace(/^\n/, ''));
		obj.html(obj.html().replace(/\n/g,'<br/>'));
		// new rendering end
	}

	function renderAITaskResponse(taskTitle, response) {
		hideProgressSpinner();

		// old rendering start
		// $('.generated-tasks-title').text(taskTitle);
		// $('.generated-tasks-container').removeClass('hidden');
		// var obj = $('.generated-tasks').text(response);
		// obj.html(obj.html().replace(/\n/g,'<br/>'));
		// old rendering end

		// new rendering start
		$('.generated-response-modal').removeClass('hidden');
		$('.generated-response-modal-title').text(taskTitle);
		var obj = $('.generated-response-content').text(response);
		obj.html(obj.html().replace(/^\n/, ''));
		obj.html(obj.html().replace(/^\n/, ''));
		obj.html(obj.html().replace(/\n/g,'<br/>'));
		// new rendering end


	}

	function copyText() {
		var textToCopy = $('.generated-response').text()
		console.log(textToCopy);
		navigator.clipboard.writeText(textToCopy);
	}

	function copyResponseText() {
		var textToCopy = $('.generated-response-content').text()
		console.log(textToCopy);
		navigator.clipboard.writeText(textToCopy);
	}

	function postFeedback(currentView, content) {
		$.ajax({
      url: "/feedback",
      type: "post",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      data: {
      	content: content,
      	current_view: currentView
      },
      success: function(response) {
        console.log(response);
        console.log('response received from post feedback');
       	feedbackSubmittedReset();
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response.responseText);
      },
    });
	}


	function getSortedStudents(courseSectionId, sortMethod) {
		console.log(courseSectionId);
		console.log(sortMethod);
		$.ajax({
      url: "/get_sorted",
      type: "post",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      data: {
      	id: courseSectionId,
      	sort_by: sortMethod
      },
      success: function(response) {
        console.log(response);
        renderSortStudentList(response);
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response.responseText);
      },
    });
	}



	function saveStudentContact(studentId, date, element) {
		console.log('logging from saveStudentContact');
		console.log(studentId);
		console.log(date);
		$.ajax({
      url: "/student_contacts",
      type: "post",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      data: {
      	student_id: studentId,
      	date: date
      },
      success: function(response) {
        var selectedDate = response.student_contact.date.split("T")[0]
        element.addClass('bg-green-600');
        element.addClass('text-white');
        var $elementToColor = element.closest('.student-top-container').find('.activity-circle');
        var $thisContainer = element.closest('.student-top-container');
        console.log('hiding calendar');
        $thisContainer.removeClass('student-container-selected');
        $thisContainer.addClass('student-container');
        console.log($thisContainer);
        var $thisCalendar = $thisContainer.children('div').last();
        console.log($thisCalendar);
        $thisCalendar.removeClass('block');
        $thisCalendar.addClass('hidden');
        changeColorAfterContact(response, $elementToColor);
      
        
        // change all associated selects
        // need to send all components for that course here?
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response.responseText);
      },
    });
	}

	function generatePhonePin() {
  	console.log('logging from generatePhonePin');
  		
		$.ajax({
      url: "/generate_phone_pin",
      type: "post",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      data: {
      	logged_in:  "true",
      },
      success: function(response) {
        console.log(response);
        
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response.responseText);
 
      },
    });
	}


  function generateTasks(taskName, topicId) {
  	console.log('logging from generateTasks');
  	console.log(taskName);
  	console.log(topicId);
  	if (taskName == 'summary') {
  		var taskTitle = "AI Summary";
  		var taskUrl = "/generate_summary.json";
  	} else if (taskName == 'announcement') {
  		var taskTitle = "AI Announcement";
  		var taskUrl = "/generate_announcement.json";
  		} else if (taskName == 'questions') {
  		var taskTitle = "AI Announcement";
  		var taskUrl = "/generate_furthering_questions.json";
  		} else if (taskName == 'examples') {
  		var taskTitle = "AI Announcement";
  		var taskUrl = "/generate_examples.json";
  	}	else if (taskName == 'quiz-questions') {
  		var taskTitle = "AI Quiz Questions";
  		var taskUrl = "/generate_quiz_questions.json";
  	} else if (taskName == 'term-paper') {
  		var taskTitle = "AI Term Paper Instructions";
  		var taskUrl = "/generate_term_paper.json";
  	}	else if (taskName == 'lecture-notes') {
  		var taskTitle = "AI Lecture Notes";
  		var taskUrl = "/generate_lecture_notes.json";
  	}
  		
		$.ajax({
      url: taskUrl,
      type: "post",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      data: {
      	master_topic_id:  topicId,
      },
      success: function(response) {
        console.log(response);
        renderAITaskResponse(taskTitle, response);
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response.responseText);
        renderAITaskResponse(taskTitle, response.responseText);
      },
    });
	}


	  function generateSubTasks(taskName, subTopicId) {
  	
  	if (taskName == 'key-points') {
  		var taskTitle = "AI Key Points";
  		var taskUrl = "/generate_key_points.json";
  	} else if (taskName == 'additional-resources') {
  		var taskTitle = "AI Additional Resources";
  		var taskUrl = "/generate_additional_resources.json";
  	} else if (taskName == 'class-activity') {
  		var taskTitle = "AI Class Activity";
  		var taskUrl = "/generate_class_activity.json";
  	} else if (taskName == 'home-activity') {
  		var taskTitle = "AI Home Activity";
  		var taskUrl = "/generate_home_activity.json";
  	} else if (taskName == 'worksheet') {
  		var taskTitle = "AI Worksheet";
  		var taskUrl = "/generate_worksheet.json";
  	} else if (taskName == 'word-problems') {
  		var taskTitle = "AI Word Problems";
  		var taskUrl = "/generate_word_problems.json";
  	} 
  		// else if (taskName == 'examples') {
  	// 	var taskTitle = "AI Announcement";
  	// 	var taskUrl = "/generate_examples.json";
  	// }	else if (taskName == 'quiz-questions') {
  	// 	var taskTitle = "AI Quiz Questions";
  	// 	var taskUrl = "/generate_quiz_questions.json";
  	// } else if (taskName == 'term-paper') {
  	// 	var taskTitle = "AI Term Paper Instructions";
  	// 	var taskUrl = "/generate_term_paper.json";
  	// }	else if (taskName == 'lecture-notes') {
  	// 	var taskTitle = "AI Lecture Notes";
  	// 	var taskUrl = "/generate_lecture_notes.json";
  	// }
  		
		$.ajax({
      url: taskUrl,
      type: "post",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      data: {
      	sub_topic_id:  subTopicId,
      },
      success: function(response) {
        console.log(response);
        renderAITaskResponse(taskTitle, response);
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response.responseText);
        renderAITaskResponse(taskTitle, response.responseText);
      },
    });
	}



	function generateCourseTasks (taskName, courseId) {

		if (taskName == 'midterm-exam') {
			var taskTitle = "AI Midterm Exam";
  		var taskUrl = "/generate_midterm_exam.json";
  	} else if (taskName == 'final-exam') {
  		var taskTitle = "AI Final Exam";
  		var taskUrl = "/generate_final_exam.json";
  	}	

		$.ajax({
      url: taskUrl,
      type: "post",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      data: {
      	master_course_id:  courseId
      },
      success: function(response) {
        console.log(response);
        renderAITaskResponse(taskTitle, response);
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response);
        renderAITaskResponse(taskTitle, response.responseText);
      },
    });
	}



	function sendStudentPostToAI (topic, message, customizers) {
		$.ajax({
      url: "/generate_introduction_response.json",
      type: "post",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      data: {
      	discussion_topic_id:  topic,
      	message: message,
      	customizers: customizers
      },
      success: function(response) {
        console.log(response);
        renderAIResponse(response);
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response);
        renderAIResponse(response.responseText);
      },
    });
	}

	function updateRevision(component_id, approval_status) {
		$.ajax({
      url: "/update_revision.json",
      type: "post",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      data: {
      	component_id:  component_id,
      	approved: approval_status,
      },
      success: function(response) {
        console.log(response);
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response.responseText);
      },
    });
	}

	function setNewPosition(componentId, newPosition) {
		console.log('logging from setNewPosition');
		console.log(componentId);
		console.log(newPosition);
		$.ajax({
      url: "/set_position",
      type: "post",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      data: {
      	component_id: componentId,
      	position: newPosition
      },
      success: function(response) {
        console.log(response);
        if(!alert('Success! All interactions saved.')){window.location.reload();}
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response.responseText);
      },
    });
	}


	function saveBulkSelected(selectedStudents) {
		console.log('logging from savebulkselected');
		$.ajax({
      url: "/student_contacts/bulk_create",
      type: "post",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      data: {
      	selected_students: selectedStudents
      },
      success: function(response) {
        console.log(response);
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response.responseText);
      },
    });
	}


	function saveComment(content, componentId, file, thisObject) {
		console.log('logging from savecomment');
		console.log(content);
		var formData = new FormData();
		if (file === undefined) {
			console.log('no file attached');
		} else {
			formData.append('file', file);
		}
		formData.append('content', content);
		formData.append('component_id', componentId);
		
		console.log(thisObject);
		$.ajax({
      url: "/comments",
      type: "post",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      cache : false,
      contentType: false,
    	processData: false,
      data: formData,
      success: function(response) {
        console.log(response);
        appendNewComment(response.content, response.first_name, response.last_name, thisObject);
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response.responseText);
      },
    });
	}

	function commentUpvote(commentId, thisObject) {
		console.log(thisObject);
		$.ajax({
      url: "/comments/upvote",
      type: "patch",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      data: {
        id: commentId	
      },
      success: function(response) {
        console.log(response);
        updateCommentVoteTotal(response.cached_votes_score, thisObject)
        hideCommentVoteButtons(thisObject);
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response.responseText);
      },
    });
	}

	function commentDownvote(commentId, thisObject) {
		console.log(thisObject);
		$.ajax({
      url: "/comments/downvote",
      type: "patch",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      data: {
        id: commentId	
      },
      success: function(response) {
        console.log(response);
        updateCommentVoteTotal(response.cached_votes_score, thisObject)
        hideCommentVoteButtons(thisObject);
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response.responseText);
      },
    });
	}

	function componentUpvote(componentId, thisObject) {
		console.log(thisObject);
		$.ajax({
      url: "/components/upvote",
      type: "patch",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      data: {
        id: componentId	
      },
      success: function(response) {
        console.log(response);
        updateComponentVoteTotal(response.cached_votes_score, thisObject)
        hideComponentVoteButtons(thisObject);
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response.responseText);
      },
    });
	}

	function componentDownvote(componentId, thisObject) {
		console.log(thisObject);
		$.ajax({
      url: "/components/downvote",
      type: "patch",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: "json",
      data: {
        id: componentId	
      },
      success: function(response) {
        console.log(response);
        updateComponentVoteTotal(response.cached_votes_score, thisObject)
        hideComponentVoteButtons(thisObject);
      },
      error: function(response) {
        console.log("logging as an error");
        console.log(response.responseText);
      },
    });
	}

	function renderSortStudentList(studentArray) {
		studentArray.forEach(function(student) {
    	console.log(student)
		});
	}

	function changeColorAfterContact(response, element) {
		console.log(response)
		if (response.not_contacted_in_last_two_weeks) {
			element.removeClass('bg-green-500');
			element.removeClass('bg-yellow-500');
      element.addClass('bg-red-500');
		}
		else if (response.not_contacted_in_last_week) {
			element.removeClass('bg-red-500');
			element.removeClass('bg-green-500');
      element.addClass('bg-yellow-500');
		}
		else {
			element.removeClass('bg-red-500');
      element.removeClass('bg-yellow-500');
      element.addClass('bg-green-500');
		}
	}

	function hideCommentVoteButtons(thisObject) {
		thisObject.parent().find('.comment-upvote-button').hide();
		thisObject.parent().find('.comment-downvote-button').hide();
	}

	function hideComponentVoteButtons(thisObject) {
		thisObject.parent().find('.component-upvote-button').hide();
		thisObject.parent().find('.component-downvote-button').hide();
	}


	function updateCommentVoteTotal(newTotal, thisObject) {
		console.log('calling updatecommentvotetotal');
		console.log(newTotal);
		console.log(thisObject);
		thisObject.parent().find('.comment-vote-score').text(newTotal);
	}

	function updateComponentVoteTotal(newTotal, thisObject) {
		console.log('calling updateComponentVoteTotal');
		console.log(newTotal);
		console.log(thisObject);
		var $newDiv = $('<div>');
		$newDiv.addClass('w-18 inline-block text-center');
		var $newCircle = $('<p>');
		if (newTotal > 0) {
			$newCircle.addClass('h-8 w-8 bg-green-600 inline-block rounded-full text-white text-center text-lg leading-7');
		} else {
			$newCircle.addClass('h-8 w-8 bg-red-600 inline-block rounded-full text-white text-center text-lg leading-7');
		}
		$newCircle.text(newTotal);
		var $newText = $('<p>');
		$newText.addClass('block text-xs text-gray-400').text('vote total');
		$newDiv.append($newCircle);
		$newDiv.append($newText);
		thisObject.parent().append($newDiv);
	}

	function resetCommentButton(thisObject) {
		thisObject.siblings('.comment-input').addClass('hidden');
		thisObject.siblings('.comment-input').val('');
		thisObject.removeClass('bg-blue-600');
		thisObject.addClass('bg-green-600');
		thisObject.text("+ comment");
		thisObject.addClass("add-comment-button");
		thisObject.removeClass("submit-comment-button");
		thisObject.siblings('input').addClass('hidden');
		thisObject.siblings('.comment-input-cancel').addClass('hidden');
	}
	function appendNewComment(content, firstName, lastName, thisObject) {
		console.log(thisObject);
		thisObject.closest('.component-container').find('.comments-container').append(`<p class="text-sm">${content} 
			<span class='text-xs text-gray-400'>${firstName} ${lastName}</span></p>`)
	}

});